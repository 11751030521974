import { Vue, Component } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BLink
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import { MasterDataViewModel, ProductListViewModel } from '@/api/api';
import {
  productListDispatcher,
  ProductListInput,
  productListNamespace
} from './store/product-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import ApiClientFactory from '@/api/apiClientFactory';
import { DEFAULT_PAGE_SIZE } from '@/utility/pagination/pagination';
import { ProductStatus } from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions, StringFormat } from '@/utility/utils';
import router from '@/router';
import ProcessingSettingModal from '@/components/processingSetting/ProcessingSettingModal.vue';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { mapActions, mapState } from 'vuex';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    BLink,
    ProcessingSettingModal
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_PAGE_SIZE,
        searchText: ''
      },
      dispatcher: productListDispatcher,
      debouncedWatchers: [(listInput) => listInput.searchText]
    })
  ],
  computed: {
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class ProductList
  extends Vue
  implements ListVue<ProductListViewModel, ProductListInput> {
  @productListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    ProductListViewModel,
    ProductListInput
  >[ListGetter.state];
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  PRODUCT_STATUS_ENUM = ProductStatus;

  productStatusOptions: DropdownOption[] = enumToDropdownOptions(ProductStatus);

  $refs!: {
    processingSettingModal: any;
  };

  mounted() {
    if (!this.masterData) {
      this.fetchMasterData();
    }
    productListDispatcher.load();
  }

  displayProductStatus(value) {
    return this.productStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.productStatusOptions.find((x) => x.value === value)
            ?.text as string
        ).toString()
      : '';
  }

  add() {
    router.push({ path: 'product-form' });
  }

  edit(id: number) {
    router.push({ path: `product-form/${id}` });
  }

  remove(id: number) {
    const client = new ApiClientFactory().productClient();
    client.delete(id).then(() => {
      productListDispatcher.load();
      // show toast
      this.$bvToast.toast(
        StringFormat(
          this.$t('DeleteSuccessfully').toString(),
          this.$t('Product').toString().toLowerCase()
        ),
        {
          title: this.$t('Product').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  settingProcessing(id, name) {
    this.$refs.processingSettingModal.loadProcessingSetingModal(id, name);
  }

  isNotAbleToSettingProcessing(categoryId: number): boolean {
    const catalogueCategoryId = this.masterData.catalogueCategoryId;
    const stickerCategoryId = this.masterData.stickerCategoryId;

    return (
      categoryId === catalogueCategoryId || categoryId === stickerCategoryId
    );
  }

  headers = [
    {
      label: this.$t('Image'),
      field: 'image',
      sortable: false,
      width: '3%'
    },
    {
      label: this.$t('Name'),
      field: 'name'
    },
    {
      label: this.$t('Category'),
      field: 'productCategoryName',
      width: '20%'
    },
    {
      label: this.$t('DisplayNumber'),
      field: 'displayNumber',
      width: '15%'
    },
    {
      label: this.$t('Status'),
      field: 'status',
      width: '15%'
    },
    {
      label: this.$t('SeoUrl'),
      field: 'seoUrl',
      width: '15%',
      sortable: false
    },
    {
      label: '',
      field: '_action',
      sortable: false,
      width: '1%'
    }
  ];
}
